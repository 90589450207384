@import '../../../styles/base.sass'

.loading
    display: flex
    flex-direction: column
    align-items: center
    margin: auto
    widows: 100%
    height: 100%
    align-self: center
    justify-self: center
    &__keyboard
        border: 1px solid black
        border-radius: 4px
        width: 100px
        display: flex
        flex-wrap: wrap
        padding: 2px
        justify-content: center
        &__key
            background: gray
            margin: 0.7px
            border-radius: 2px
            width: 10px
            height: 10px
            transition: all .3s ease-in-out
    .pushed
        transition: all .3s ease-in-out
        background: #424242
        transform: scale(.875)

    &__text
      .typewriter 
        width: auto
        overflow: hidden
        border-right: .15em solid $purple
        white-space: nowrap
        margin: 0 auto
        letter-spacing: .15em
        animation: typing 3.5s steps(10, end), blink-caret .75s step-end infinite
        margin-bottom: 2em



/* The typing effect
@keyframes typing
  from
    width: 0

  to
    width: 100%

/* The typewriter cursor effect
@keyframes blink-caret
  from, to
    border-color: transparent

  50%
    border-color: $purple