@import 'normalize.sass'
@import 'vars.sass'

//site layout variables to keep math consistent, only variables that go here are ones that deal exclusively with site wide settings, settings used in separate components live in vars.sass
// root
//   --nav-height: 70px
//   --spacer-top: 2.5em
// .base-css-vars

  //remeber that some components may be using spacer top height instead of the class top-space like in projects.js need to swap over to class-based

// .base-css-vars-two
$navHeight: 70px
$spacerTopHeight: 8em
$spacerContentHeight: 2em

//general resets and settings
html
  font-size: 12pt
  font-weight: 300
  color: $main-dark
  // text-rendering: optimizeLegibility
  // -webkit-font-smoothing: none
  // -webkit-font-smoothing: subpixel-antialiased
  // -webkit-font-smoothing: antialiased
  // -webkit-font-smoothing: subpixel-antialiased
  // font-smoothing: subpixel-antialiased
  --notification-height: 0px
  --nav-height: 70px
  @media(max-width: 1050px)
    --nav-height: 62px
    //maybe remove
    font-size: 1.4vw !important
  @include edgesnap
    font-size: 1.275vw !important
  @media(max-width: 950px)
    font-size: 1.475vw !important
  @media(max-width: 900px)
    //may want to delete
    font-size: 1.5vw !important
  @include tablet
    font-size: 1.575vw !important
  @media(max-width: 800px)
    //may want to delete
    --nav-height: 55px
    font-size: 1.725vw !important
  @media(max-width: 720px)
    --nav-height: 52px
  @media(max-width: 650px)
    font-size: 1.825vw !important
  @media(max-width: 575px)
    font-size: 1.935vw !important
  @include tablet-phone
    font-size: 2.5vw !important
    // font-size: 16px !important
    --nav-height: 46px
  @include phone
    font-size: 3.1vw !important
    --nav-height: 50px

  body
    font-family: $mainfont
    position: relative
    overflow-x: hidden
    &.modal-open
      // padding: 2.5em 0
      overflow-y: hidden
      // overflow-y: scroll
    .top-space
      margin-top: 2.7em
      @include edgesnap
        margin-top: 2.5em
      @include tablet
        margin-top: 2.25em
      @media(max-width: 650px)
        margin-top: 1.9em
    a
      font-size: .95em
    button
      font-size: 1em
      font-family: $mainfont
    h1
      // font-size: 2.15em
      // font-weight: 400
    h2
      font-size: 2.15em
      font-weight: 400
      // color: green !important
      &.account-title
        margin-bottom: 1em
      @media(max-width: 950px)
        font-size: 2em
      // @media(max-width: 650px)
        // font-size: 2.25em
    h3
      font-size: 1.80em
      font-weight: 400
      // color: pink !important
      @media(max-width: 950px)
        font-size: 1.6em
      // @media(max-width: 650px)
        // font-size: 1.8em
      @include phone
        font-size: 1.9em
        // margin-bottom:
      &.info-title
        text-align: center
        margin-bottom: .5em
        @media(max-width: 650px)
          margin-bottom: .4em
        @media(max-width: 575px)
          margin-bottom: .55em
        @include phone
          // text-align: left
          margin-bottom: .2em
    h4
      font-size: 1.6em
      font-weight: 400
      @media(max-width: 950px)
        font-size: 1.5em
      // color: red !important 

    p
      justify-self: center
      &.info-text
        display: flex
        align-self: center
        color: $light-grey
        text-align: center
        width: 70%
        font-size: 1em
        @include tablet
          width: 75%
        @media(max-width: 650px)
          width: 80%
          font-size: .95em
        // @media(max-width: 575px)
          // margin-bottom: .55em
        @include phone
          font-size: 1.1em
          width: 85%
    input
      font-family: 'Nunito'
      -webkit-appearance: none
      border-radius: 0
      box-shadow: none
      outline: none
    textarea
      font-family: 'Nunito'

//main website layout components
.page
  display: flex
  width: 100%
  flex-direction: column
.wrapper
  display: flex
  justify-content: center
  width: 100%
  // background: pink
  padding: 0px 60px
  @include edgesnap
    padding: 0px 120px
  @media(max-width: 950px)
    padding: 0px 75px
  @media(max-width: 700px)
    padding: 0px 68px
  @include tablet-phone
    padding: 0px 45px
  @media(max-width: 575px)
    padding: 0px 42px
  @media(max-width: 450px)
    padding: 0px 38px
  @include phone
    // padding: 0px 25px
    padding: 0px 15px
    // padding: 0px 0px

  .content
    display: flex
    align-items: flex-start
    width: $site-width
    // width: 100%
    // background: lightskyblue
    @include edgesnap
      width: 100%
    &.main-site
      $h: $navHeight
      $s: $spacerTopHeight
      $p: 100vh
      --added-height: calc(#{$h} + #{$s})
      min-height: calc(100vh - var(--added-height))
  .wrapper-full
    background: red
    height: 500px

//spacers
.spacer
  display: flex
  &.top
    // background: rgba(0,0,0,0.05)
    height: $spacerTopHeight
    @media(max-width: 950px)
      height: 7em
    // @media(max-width: 850px)
    @media(max-width: 750px)
      height: 8em
    @media(max-width: 650px)
      height: 7.75em
    @include tablet-phone
      height: 5.35em
    @include phone
      height: 5.25em

  // &::after
    // content: 'spacer top'
    // text-align: center
  &.content
    height: $spacerContentHeight
    // position: relative
    @include edgesnap
      height: 2.2em
    @media(max-width: 950px)
      height: 2em
    @include tablet
      height: 1.3em
    @media(max-width: 575px)
      height: 1.6em

.md
  ul
    list-style-position: inside
  p
    margin-bottom: 1em
  h1,h2,h3,h4,h5,h6,ul,ol,img
    margin-bottom: 10px
  table
    border-collapse: collapse
    th
      text-align: left
      height: 2em
    td,th
      padding-left: .25em
      padding-right: .25em
      border: 1px solid gray
      box-sizing: border-box

span.visually-hidden
  position: absolute
  height: 1px
  width: 1px
  overflow: hidden
  clip: rect(1px 1px 1px 1px)
  clip: rect(1px, 1px, 1px, 1px)
  white-space: no-wrap
  // &:not(:focus):not(:active)
  //   position: relative
  //   height: auto
  //   width: auto
  //   overflow: auto
  //   // clip: rect(1px 1px 1px 1px)
  //   // clip: rect(1px, 1px, 1px, 1px)
  //   white-space: wrap
.mde-preview
  img
    max-width: 100%

.photo-hover
  // transform: translateX(0)
  transition: all .45s ease-in-out
  // box-shadow: 0em 0em 0px 0px rgba(155,155,155,0)
  &:hover
    transform: translateY(-.2em)
    box-shadow: 0 1px 8px 2px rgba(155,155,155,0.3)

.admin_table
  border-collapse: collapse
  tr
    th
      text-align: left
      height: 2em
      td,th
        padding-left: .25em
        min-width: 6em
        border: 1px solid gray
        box-sizing: border-box
        a
          color: teal
        &:nth-of-type(odd)
          background: lightgray
.shipping_warning
  opacity: 0
  transform: translateX(100%)
  animation: slide-in 0.3s forwards
  padding: .5em
  border-radius: 1em
  display: flex
  @include tablet
    font-size: 1.2em
  @include tablet-phone
      // font-size: 1.2em
  img
    margin-right: 1em

input[type="text"], input[type="number"], textarea
  @include tablet-phone
    font-size: 16px

@keyframes slide-in
  100%
    opacity: 1
    transform: translateX(0%)
